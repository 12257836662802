/*
Template Name: Toner eCommerce + Admin HTML Template
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Main Css File
*/

//Fonts
@import "fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins";

// Structure
@import "structure/topbar";
@import "structure/page-head";
@import "structure/footer";
@import "structure/vertical";
@import "structure/horizontal";
@import "structure/two-column";
@import "structure/layouts";
@import "structure/landing-menu";

// Components
@import "components/avatar";
@import "components/accordion";
@import "components/helper";
@import "components/preloader";
@import "components/forms";
@import "components/widgets";
@import "components/demos";
@import "components/print";
@import "components/ribbons";
@import "components/toast";
@import "components/scrollspy";

// Bootstrap components
@import "components/root";
@import "components/reboot";
@import "components/alerts";
@import "components/backgrounds";
@import "components/badge";
@import "components/buttons";
@import "components/breadcrumb";
@import "components/card";
@import "components/dropdown";
@import "components/nav";
@import "components/table";
@import "components/modal";
@import "components/pagination";
@import "components/progress";
@import "components/popover";
@import "components/type";
@import "components/form-check";
@import "components/form-control";
@import "components/list-group";

// Plugins
@import "plugins/custom-scrollbar";
@import "plugins/prismjs";
@import "plugins/sweetalert2";
@import "plugins/dropzone";
@import "plugins/sortablejs";
@import "plugins/tour";
@import "plugins/swiper";
@import "plugins/multijs";
@import "plugins/colorpicker";
@import "plugins/form-input-spin";
@import "plugins/ckeditor";
@import "plugins/gridjs";
@import "plugins/listjs";
@import "plugins/apexcharts";
@import "plugins/google-map";
@import "plugins/autocomplete";
@import "plugins/vector-maps";
@import "plugins/leaflet-maps";
@import "plugins/fullcalendar";
@import "plugins/emoji-picker";
@import "plugins/datatables";
@import "plugins/toastify"; 
@import "plugins/flatpicker";
@import "plugins/flag-input";
@import "plugins/choices";
@import "plugins/tom-select";
@import "plugins/range-slider";

// Pages
@import "pages/authentication";
@import "pages/dashboard";
@import "pages/errors";
@import "pages/profile";
@import "pages/coming-soon";

//ecommerce scss
@import "pages/ecommerce";
@import "pages/extra-pages";
@import "pages/categrory";
@import "pages/sellers";
@import "pages/invoice";


.custom-vector-map  {
    svg {
        width: 100%;
        max-height: 100%;
        path {
            fill: var(--#{$prefix}light) !important;
        }
    }
}


// .red-day {
//     background-color: #f75555 !important;
//     color: #fff !important;
//     border-radius: 22px solid #fff !important;
//   }

//   .except-day{
//     background-color: #616161 !important;
//     color: #fff !important;
//   }
//   .selected-day{
//     background-color: #e49191 !important;
//     color: #fff !important;
//     border: 1px solid #fff;
//   }

//   .except-day.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.except-day{
//     border: 1px solid #fff;
//     //border-radius: 20px;
//     width: 100%;
//   }

  
  